//eslint-disable-next-line
const tokenName = getEnv('REACT_APP_TOKEN_NAME');

/**
 * @description Makes an api request to the backend
 * @param {*} endpoint  endpoint to make the request to
 * @param {*} method  request method to use (GET, POST, PATCH, DELETE)
 * @param {*} params  request parameters
 * @returns  response from the backend if the request was successful (statusCode , data, message)
 * @returns  error message if the request was unsuccessful (statusCode , reqError, reqMessage)
 */
export async function apiRequest(endpoint, method = 'GET', params = null) {
  let result;
  const payload = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
    },
    credentials: 'include',
  };

  if (['POST', 'PATCH'].includes(method)) {
    payload.body = JSON.stringify(params);
  }

  try {
    // makes the request
    const response = await fetch(`${endpoint}`, payload);

    const body = await response.text();
    result = body ? JSON.parse(body) : {};

    if (method === 'DELETE' && response.ok) {
      if (result.status === 'ok') {
        return result;
      }
      return {
        reqError: false,
        reqStatus: 200,
        reqMessage: 'ok',
      };
    }

    if (!response.ok) {
      result = {
        reqError: true,
        reqStatus: response?.status,
        reqMessage: result?.message ?? result?.statusText,
      };
      // Logout user if 401 status was received
      if (response.status === 401) {
        sessionStorage.removeItem(tokenName);
        window.location.assign('/login');
      }
    }
    return result;
  } catch (error) {
    return {
      reqError: true,
      reqMessage: result?.message ?? result?.statusText,
    };
  }
}
