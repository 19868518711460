import CIcon from '@coreui/icons-react';
import React from 'react';
import { capitalizeFirstLetter } from '../helpers';

const Card = ({ title, total, active, icon, color, backgroundColor }) => {
  return (
    <div className="card card-c">
      <div className="card-body card-body-s">
        <div>
          <div>
            <p className="card-f-line">
              {`Total ${capitalizeFirstLetter(title)}`}
            </p>
            <p className="card-s-line">{total}</p>
          </div>
          <p className="card-td-line">{`Total  active ${title} ${active}`}</p>
        </div>
        <div
          className="icon-container"
          style={{
            backgroundColor: backgroundColor,
            color: color,
          }}
        >
          <CIcon
            icon={icon}
            style={{
              width: '60%',
              height: '60%',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
