import React, { Suspense } from 'react';
import {  Route, Routes } from 'react-router-dom';
import { CContainer, CSpinner } from '@coreui/react';
import routes from '../routes';

const AppContent = () => {
  return (
    <CContainer
      style={{
        width: '90%',
        minWidth: '94%',
        height: '100%',
      }}
    >
      <Suspense
        fallback={
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <CSpinner color="primary" />
          </div>
        }
      >
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
        </Routes>
      </Suspense>
    </CContainer>
  );
};

export default React.memo(AppContent);
