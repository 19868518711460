import React from 'react';
import CIcon from '@coreui/icons-react';
import {
  cilChartLine,
  cilChartPie,
  cilControl,
  cilHome,
  cilObjectGroup,
  cilSettings,
  cilUser,
} from '@coreui/icons';
import { CNavItem, CNavTitle } from '@coreui/react';

export const _navUser = [
  {
    component: CNavItem,
    name: 'Home',
    to: '/',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Robot',
  },
  {
    component: CNavItem,
    name: 'Activities',
    to: '/robot-control',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Brands',
    to: '/robot-brands',
    icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Robot Statistics',
    to: '/robot-statistics',
    icon: <CIcon icon={cilChartLine} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Products',
  },
  {
    component: CNavItem,
    name: 'Product List',
    to: '/product-list',
    icon: <CIcon icon={cilObjectGroup} customClassName="nav-icon" />,
  },
];
export const _navAdmin = [
  ..._navUser,
  {
    component: CNavTitle,
    name: 'Admin',
  },
  {
    component: CNavItem,
    name: 'Suppliers',
    to: '/robot-configuration',
    icon: <CIcon icon={cilControl} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Users',
    to: '/users',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
];
