import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CContainer, CHeader, CHeaderNav, CHeaderToggler } from '@coreui/react';
import { AppHeaderDropdown } from './header/index';
import { Space } from 'antd';
import { BsFullscreen } from 'react-icons/bs';
import { AiOutlineMenu } from 'react-icons/ai';
import { _navAdmin, _navUser } from '../_nav';
import { NavLink } from 'react-router-dom';
import { baseDarkGray, baseGreen } from '../views/theme/colors/Colors';
import Logo from '../assets/Logo.png';

const AppHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector(state => state.sidebarShow);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(null);

  const menu =
    sessionStorage.getItem('role') === 'admin' ? _navAdmin : _navUser;

  return (
    <>
      <CHeader position="sticky" className="header-desktop">
        <CContainer
          fluid
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Space>
            <CHeaderToggler
              onClick={() =>
                dispatch({ type: 'set', sidebarShow: !sidebarShow })
              }
              className="center-content paintColorGreenonHoover"
            >
              <BsFullscreen size={25} />
            </CHeaderToggler>
            <CHeaderNav>
              <AppHeaderDropdown />
            </CHeaderNav>
          </Space>
        </CContainer>
      </CHeader>
      <CHeader
        className="header-mobile"
        style={{
          backgroundColor: baseDarkGray,
          color: baseGreen,
          display: 'flex',
          flexDirection: 'row',
          height: showMobileMenu ? '100%' : '20px',
        }}
      >
        <div className="logo-container">
          <NavLink className="nav-link" to={'/'}>
            <div
              style={{
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '5px',
              }}
            >
              <img
                src={Logo}
                alt="logo"
                className="logo"
                width={'100%'}
                height={'100%'}
                style={{ borderRadius: '10px' }}
              />
            </div>
          </NavLink>
        </div>
        <CHeaderToggler
          onClick={() => setShowMobileMenu(!showMobileMenu)}
          className="center-content paintColorGreenonHoover  menu-container"
        >
          <AiOutlineMenu size={25} />
        </CHeaderToggler>

        {showMobileMenu && (
          <div className="links-container">
            {/* eliminate the dividers from the menu first */}
            {menu
              ?.filter(item => item.to)
              ?.map((item, index) => (
                <NavLink
                  key={index}
                  to={item.to}
                  className="nav-link"
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    backgroundColor:
                      selectedLinkIndex === index ? baseGreen : '',
                    color: selectedLinkIndex === index ? 'white' : '',
                  }}
                  onClick={() => {
                    setSelectedLinkIndex(index);
                    setTimeout(() => {
                      setShowMobileMenu(!showMobileMenu);
                    }, 200);
                  }}
                >
                  {item.name}
                </NavLink>
              ))}
          </div>
        )}
      </CHeader>
    </>
  );
};

export default AppHeader;
