import React, { useContext } from 'react';
import Login from '../../views/pages/login/Login';
import DefaultLayout from '../../layout/DefaultLayout';

import { AuthorizationContext } from '../auth/AuthProvider';

//eslint-disable-next-line
const tokenName = getEnv('REACT_APP_TOKEN_NAME');
const PrivateRoute = () => {
  const { checkIfLoggedIn } = useContext(AuthorizationContext);

  return checkIfLoggedIn(tokenName) ? <DefaultLayout /> : <Login />;
};

export default PrivateRoute;
