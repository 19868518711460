import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react';
import { AppSidebarNav } from './AppSidebarNav';
import SimpleBar from 'simplebar-react';
import { _navAdmin, _navUser } from '../_nav';
import { useNavigate, useLocation } from 'react-router';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';
import Logo from '../assets/Logo.png';

const AppSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const unfoldable = useSelector(state => state.sidebarUnfoldable);
  const sidebarShow = useSelector(state => state.sidebarShow);
  const menu =
    sessionStorage.getItem('role') === 'admin' ? _navAdmin : _navUser;

  useEffect(() => {
    if (window.innerWidth < 1024)
      dispatch({ type: 'set', sidebarUnfoldable: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <CSidebar
      narrow={unfoldable}
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={visible => {
        dispatch({ type: 'set', sidebarShow: visible });
      }}
      style={{ backgroundColor: '#17171B' }}
    >
      <CSidebarBrand
        className="d-none d-md-flex"
        to="/"
        style={{
          backgroundColor: '#17171B',
        }}
      >
        <h3 onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
          {!unfoldable ? (
            'Dynamic Hub'
          ) : (
            <div
              style={{
                width: '35px',
                height: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '5px',
                borderRadius: '10px',
              }}
            >
              <img
                src={Logo}
                alt="logo"
                className="logo"
                width={'100%'}
                height={'100%'}
                style={{ borderRadius: '10px' }}
              />
            </div>
          )}
        </h3>
        {sidebarShow && (
          <span
            onClick={() =>
              dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })
            }
            className="sidebar-toggle"
          >
            {unfoldable ? <AiOutlineDoubleRight /> : <AiOutlineDoubleLeft />}
          </span>
        )}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar
          style={{
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <AppSidebarNav items={menu} />
        </SimpleBar>
      </CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
