import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import AuthorizationProvider from './components/auth/AuthProvider';

import ProtectedRoute from './components/auth/ProtectedRoute';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  return (
    <Router>
      <AuthorizationProvider>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="*" element={<ProtectedRoute />}></Route>
          </Routes>
        </Suspense>
      </AuthorizationProvider>
    </Router>
  );
};

export default App;
