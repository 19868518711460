import React, { useContext } from 'react';
import {
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import { cilAccountLogout } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { AuthorizationContext } from '../auth/AuthProvider';
import { FaRegUserCircle } from 'react-icons/fa';

const AppHeaderDropdown = () => {
  const { logout } = useContext(AuthorizationContext);

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle
        placement="bottom-end"
        className="py-0  center-content paintColorGreenonHoover"
        caret={false}
      >
        <FaRegUserCircle size={30} />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">
          Account
        </CDropdownHeader>
        <CDropdownDivider />
        <CDropdownItem href="/login" onClick={() => logout()}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
