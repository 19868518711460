import React, { useContext, useEffect, useState } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { notification } from 'antd';
import { cilLockLocked, cilUser } from '@coreui/icons';
import {
  AuthorizationContext,
  LottieAnimation,
  animation_cloud,
} from '../../../components';
import { openNotificationWithIcon } from '../../../helpers';

const Login = () => {
  const [api, contextHolder] = notification.useNotification();
  const { login } = useContext(AuthorizationContext);
  const [animate, setAnimate] = useState(false);

  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const handleChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  /**
   * @description: handle submit form
   * @param: e - event object
   * @param: user - user object (email, password)
   * @returns: void
   */
  const handleSubmit = async e => {
    e.preventDefault();
    if (!user) {
      openNotificationWithIcon(
        api,
        'error',
        'Error',
        'Credentials is required'
      );
      return;
    }
    if (!user.email) {
      openNotificationWithIcon(api, 'error', 'Error', 'Email is required');
      return;
    }
    if (!user.password) {
      openNotificationWithIcon(api, 'error', 'Error', 'Password is required');
      return;
    }
    setAnimate(true);
    let response = null;
    setTimeout(async () => {
      response = await login(user);
      if (response?.reqMessage) {
        openNotificationWithIcon(api, 'error', 'Error', response.reqMessage);
      }
      if (response?.statusCode === 200) {
        openNotificationWithIcon(api, 'success', 'Success', response.message);
      }
      setAnimate(false);
    }, 2500);
  };

  const style = {
    height: 400,
  };

  const interactivity = {
    mode: 'loop',
  };
  /**
   * @description: handle enter key press
   * @param: e - event
   * @returns: cleanup function to remove the event listener
   */
  useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === 'Enter') {
        handleSubmit(e);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      // Cleanup: remove the event listener when the component unmounts
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSubmit]);

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center login-page">
      <CContainer>
        {contextHolder}
        {!animate ? (
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm>
                      <h1>Login</h1>
                      <p className="text-medium-emphasis">
                        Sign In to your account
                      </p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          placeholder="Username"
                          autoComplete="username"
                          name="email"
                          onChange={handleChange}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          onChange={handleChange}
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          name="password"
                        />
                      </CInputGroup>
                      <CRow>
                        <CCol xs={6}>
                          <CButton
                            color="primary"
                            className="px-4"
                            onClick={handleSubmit}
                          >
                            Login
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
                <CCard
                  className="text-white bg-primary py-5  color-container-blue  container-hide-mobile"
                  style={{
                    width: '44%',
                    // litle box shadow to look good with #003f6e
                    boxShadow: ` rgba(136, 165, 191, 0.2) 6px 2px 16px 0px, rgba(255, 255, 255, 0.1) -6px -2px 16px 0px`,
                  }}
                >
                  <CCardBody className="text-center">
                    <div>
                      <h2>Dynamic Hub</h2>
                      <p
                        style={{
                          fontSize: '1rem',
                        }}
                      >
                        Say goodbye to the time-consuming process of manually
                        searching the web for product information. Our advanced
                        scraping technology simplifies data collection, giving
                        you a distinct advantage in the online marketplace.
                      </p>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        ) : (
          <div className="center-content animation-container">
            <LottieAnimation
              jsonData={animation_cloud}
              interactivity={interactivity}
              style={style}
              loop
            />
          </div>
        )}
      </CContainer>
    </div>
  );
};

export default Login;
