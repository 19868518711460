import React from 'react';
import {
  AppContent,
  AppSidebar,
  AppFooter,
  AppHeader,
} from '../components/index';
import { notification } from 'antd';

const DefaultLayout = () => {
  const [ api,contextHolder] = notification.useNotification();
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light h-100">
        <AppHeader />
        {contextHolder}
        <div className="body flex-grow-1 px-2 pt-4 new_background">
          <AppContent />
        </div>
        <AppFooter />
      </div>
    </div>
  );
};

export default DefaultLayout;
  