import Lottie from 'lottie-react';
import React from 'react';

const LottieAnimation = ({ jsonData, style, interactivity }) => {
  return (
    <Lottie
      animationData={jsonData}
      style={style}
      interactivity={interactivity}
    />
  );
};

export default LottieAnimation;
