import { Column } from '@ant-design/plots';
import React from 'react';

const Chart = ({ categories }) => {
  // Convert 'products' values to numbers
  const numericCategories = categories.map(category => ({
    ...category,
    products: Number(category.products),
  }));

  // Find the maximum value in the 'products' array
  const maxProducts = Math.max(
    ...numericCategories.map(category => category.products)
  );
  const config = {
    data: numericCategories, // Use the converted numeric values
    xField: 'category',
    yField: 'products',
    seriesField: '', // If not needed, leave it empty
    color: '#8709b5',
    label: {
      content: '',
      offset: 10,
    },
    xAxis: {
      label: {
        content: '',
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      label: {
        content: '',
      },
      min: 5, // Set a custom starting point for the y-axis
      max: maxProducts, // Set the maximum value based on the 'products' data
    },
  };

  return <Column {...config} />;
};

// Usage

export default Chart;
