import React from 'react';

const AppFooter = () => {
  return (
    <div className="footer footer-col">
      <ul className="footer-list">
        <li className="footer-li">
          <a
            href="https://digisinergy.ro/?lang=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="span-company">DigiSinergy</span>
          </a>
          <span className="ms-1">&copy; {new Date().getFullYear()} </span>
        </li>
        <li className="footer-li">
          <span className="me-1">Powered by</span>
          <a
            href="https://www.tarainteractive.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="me-3"
          >
            <span className="span-company">TARA</span>Interactive
          </a>
        </li>
      </ul>
    </div>
  );
};

export default React.memo(AppFooter);
