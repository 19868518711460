import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { apiRequest } from '../../helpers';

const loggedInKey = 'loggedInKey';
//eslint-disable-next-line
const tokenName = getEnv('REACT_APP_TOKEN_NAME');
//eslint-disable-next-line
const apiUrl = getEnv('REACT_APP_API');

export const AuthorizationContext = React.createContext({
  userData: null,
  login: () => {
    return;
  },
  logout: () => {
    return;
  },
  checkIfLoggedIn: () => {
    return;
  },
});

/**
 * @returns Authorization provider for the App
 */
const AuthorizationProvider = ({ children }) => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);

  //Login
  const login = async x => {
    const res = await apiRequest(`${apiUrl}auth/login`, 'POST', x);

    if (res.data) {
      sessionStorage.setItem(tokenName, res.data?.access_token);
      localStorage.setItem(loggedInKey, true);
      setLoggedIn(true);
      await getProfile();
      navigate('/');
    }
    return res;
  };

  const getProfile = async () => {
    const res = await apiRequest(`${apiUrl}auth/profile`, 'GET');
    if (res.data) {
      sessionStorage.setItem('role', res.data.role);
      setUserData(res.data);
    }
  };

  /**
   * @description Checks if the user is logged in
   * @param {*} xTokenName
   * @returns {boolean} true if the user is logged in, false otherwise
   */
  const checkIfLoggedIn = xTokenName => {
    //get token from session storage
    const token = sessionStorage.getItem(xTokenName);
    return !!token;
  };

  const logout = useCallback(async () => {
    setUserData(null);
    setLoggedIn(false);
    localStorage.removeItem(loggedInKey);
    sessionStorage.removeItem(tokenName);
  }, []);

  const contextValue = {
    userData,
    loggedIn,
    checkIfLoggedIn,
    login,
    logout,
  };

  return (
    <AuthorizationContext.Provider value={contextValue}>
      {children}
    </AuthorizationContext.Provider>
  );
};

export default AuthorizationProvider;
