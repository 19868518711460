export const statuses = [
  {
    key: 0,
    value: 'Pending',
  },
  {
    key: 1,
    value: 'In Process',
  },
  {
    key: 2,
    value: 'Done',
  },
  {
    key: 3,
    value: 'On Hold',
  },
  {
    key: 4,
    value: 'Cannot Process',
  },
];

export const productStatuses = [
  { key: 0, value: 'Not Processed' },
  { key: 1, value: 'Exported' },
  { key: 2, value: 'Processed' },
  { key: 3, value: 'Error' },
];

export const monthIndexMap = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};
