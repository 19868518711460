export const parseMillisecondsToTime = milliseconds => {
  if (!milliseconds) {
    return { min: 0, sec: 0 };
  }

  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return { min: minutes, sec: seconds };
};

export const parseSeconds = seconds => {
  if (!seconds) {
    return { min: 0, sec: 0 };
  }

  const minutes = Math.floor(seconds / 60);
  const sec = seconds % 60;

  return { min: minutes, sec: sec };
};
