import React from 'react';

const Home = React.lazy(() => import('./views/pages/home/Home'));
const Users = React.lazy(() => import('./views/pages/users/Users'));
const RobotConfig = React.lazy(() => import('./views/pages/robot/RobotConfig'));
const RobotStats = React.lazy(() => import('./views/pages/robot/RobotStats'));
const RobotControl = React.lazy(() =>
  import('./views/pages/robot/RobotControl')
);
const Profile = React.lazy(() => import('./views/pages/users/Profile'));
const ProductList = React.lazy(() =>
  import('./views/pages/products/ProductList')
);
const ProductDetails = React.lazy(() =>
  import('./views/pages/products/ProductDetails')
);
const RobotBrands = React.lazy(() => import('./views/pages/robot/RobotBrands'));

const routes = [
  { path: '/', exact: true, name: 'Home', element: Home },
  { path: '/users', exact: true, name: 'Users', element: Users },
  { path: '/profile', exact: true, name: 'Profile', element: Profile },
  {
    path: '/robot-configuration',
    exact: true,
    name: 'Robot Configuration',
    element: RobotConfig,
  },
  {
    path: '/robot-control',
    exact: true,
    name: 'Robot Control',
    element: RobotControl,
  },
  {
    path: '/robot-brands',
    exact: true,
    name: 'Robot Brands',
    element: RobotBrands,
  },
  {
    path: '/robot-statistics',
    exact: true,
    name: 'Robot Statistics',
    element: RobotStats,
  },
  {
    path: '/product-list',
    exact: true,
    name: 'Product List',
    element: ProductList,
  },
  {
    path: '/product-list/:id',
    exact: true,
    name: 'Product List',
    element: ProductDetails,
  },
];

export default routes;
